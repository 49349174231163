.elevate {
    box-shadow: 0px 15px 15px 0px rgba(14, 17, 51, 0.1);
}

.dot-separation::after {
    content: "·";
    width: 1em;
    text-align: center;
    position: relative;
    color: #007bff;
}

.video-popup-overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 2;
  }
  
  .appie-video-player-item .thumb {
    -webkit-box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.64);
    -moz-box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.64);
    box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.64);
  }